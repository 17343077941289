<template>
  <div id="submenu">
    <router-link :to="{ name: 'AccountOverview' }" class="list-group-item" :class="{active: menu_list.overview}">Account Overview</router-link>
    <router-link :to="{ name: 'ShipmentTracking' }" class="list-group-item" :class="{active: menu_list.shipping||menu_list.po}">Order Status</router-link>
    <router-link :to="{ name: 'QuoteRequest' }" class="list-group-item" :class="{active: menu_list.quote || menu_list.quoteDetails}">Quote Request</router-link>
    <router-link :to="{ name: 'ChangePassword' }" class="list-group-item" :class="{active: menu_list.password}">Change Password</router-link>
    <router-link :to="{ name: 'Newsletter' }" class="list-group-item" :class="{active: menu_list.newsletter}">Email Newsletter</router-link>
    <router-link :to="{ name: 'Datafeed' }" class="list-group-item" :class="{active: menu_list.datafeed}">Data Feed Service</router-link>
    <router-link :to="{ name: 'WebService' }" class="list-group-item" :class="{active: menu_list.api}">Web Service API</router-link>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: [ 'menu' ],
  setup(props) {
    const menu_list = ref({
        overview: false,
        shipping: false,
        po: false,
        password: false,
        newsletter: false,
        datafeed: false,
        api: false,
        webDetails:false,
        quote: false,
        quoteDetails: false
      })
      
    menu_list.value[props.menu] = true
    if (props.menu === 'webDetails'){
      menu_list.value['po'] = true
    }
     
    return { menu_list }
  }
}
</script>



