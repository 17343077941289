<template>
  <breadcrumb-page
    :list="breadcrumb_links"
  ></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="webDetails"></submenu-account>

    <div class="submenu-content">
    <!-- <spinner v-if="loading" /> -->
      <div v-if="loading">
        <spinner v-if="loading" />
      </div>
      <div v-else>
        <div>
          <div class="mb-3 row">
            <h2>Web P.O. {{ webso }}</h2>
          </div>
        </div>

        <br />

        <div>
          <div class="mb-2 row">
            <div class="col-md-3 lead">
              <span>Order Placed Date:</span>
            </div>
            <div class="col-md-4">
              <span>{{po.req_date}}</span>
            </div>
          </div>

          <div class="mb-2 row">
            <div class="col-md-3 lead">
              Customer ID:
            </div>
            <div class="col-md-4">
              {{ po.custno }}
            </div>
          </div>

          <div class="mb-2 row">
            <div class="col-md-3 lead">
              Customer Company:
            </div>
            <div class="col-md-4">
              {{ po.company }}
            </div>
          </div>

          <div class="mb-2 row">
            <div class="col-md-3 lead">
              Customer Email:
            </div>
            <div class="col-md-4">
              {{po.email}}
            </div>
          </div>

          <div class="mb-2 row">
            <div class="col-md-3 lead">
              Ma Labs Sales Rep:
            </div>
            <div class="col-md-4">
              {{ po.sales_name }}
            </div>
          </div>
        </div>

        <br /><br />

        <!-- table  -->
        <!-- <i @click="printWindow" style="float:right" class="bi bi-printer-fill icon" ></i> -->
        
        <div class="tb">
          <table class="table">
            <thead class="table-light">
              <tr>
                <th scope="col">Item#</th>
                <th scope="col">Product Description</th>
                <th scope="col">Branch</th>
                <th scope="col">Qty</th>
                <th scope="col" class="text-end">Instant Rebate</th>
                <th scope="col" class="text-end">Unit Prices</th>
                <th scope="col" class="text-end">Subtotal</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="item in po_items">
                  <td>{{ item.item_no }}</td>
                  <td class="desc">
                    <div>{{ item.item_desc }}</div>
                    <div v-if="item.system == 1" class="mt-4">
                      <div class="mt-2 mb-2"><i class="bi bi-wrench-adjustable"></i> <u>Configuration Components</u>: </div> 
                      <table class="table table-condensed table-striped">
                          <tbody>
                          <tr v-for="cp in item.system_details">
                              <td width="20%" valign="top" class="pe-2">{{cp.itmno}}</td>
                              <td width="80%">{{cp.desc}} ({{cp.qty}} pcs)  </td>
                          </tr>
                          </tbody>
                      </table>
                    </div>
                  </td>
                  <td>{{ item.branch }}</td>
                  <td>{{ item.quantity }}</td>    
                  <td class="text-end">
                  <span v-if="item.sub_total_rebate != 0.0 ">
                    {{ formatPrice(item.sub_total_rebate)}}
                    <br/>
                    ({{formatPrice(item.rebate_price)}} per unit)
                    </span> 
                  <span v-else>N/A</span> 
                  </td>
                  <td class="text-end">{{formatPrice(item.price)}}</td>
                  <td class="text-end">{{formatPrice(item.sub_total)}}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <!-- table end  -->

        <!-- below table -->
        <div>
          <div class="mb-2 row justify-content-end">
            <div class="col-md-3 fw-bold text-end">
              Total Price:
            </div>
            <div class="col-md-1 text-end">{{formatPrice(po.total_price)}}</div>
          </div>

          <div class="mb-2 row justify-content-end">
            <div class="col-md-3 fw-bold text-end">
              Total Instant Rebate:
            </div>
            <div class="col-md-1 text-end">
              <span v-if="po.total_instant_rebate != '0.00'">{{formatPrice(po.total_instant_rebate)}}</span>
              <span v-else>N/A</span>
            </div>
          </div>

          <div class="mb-2 row justify-content-end">
            <div class="col-md-3 fw-bold text-end">
              Grand Total:
            </div>
            <div class="col-md-1 text-end">{{formatPrice(po.grand_total_price)}}</div>
          </div>
        </div>
        <br>
        <!-- below table end -->

        <!-- shipping info -->
        <div>
          <div class="container mb-2">
            <div class="col-md-4 lead">
              Shipping Address:
            </div>
            <div>
              <div>{{po.shipping_to_name}}</div>
              <div>{{ po.shipping_address1 }} {{po.shipping_address2}}</div>
              <div>{{po.shipping_city}}, {{po.shipping_state}} {{po.shipping_zip}}</div>
              <div>{{po.shipping_country}}</div>
            </div> 
          </div>

          <br>

          <div class="container mb-2">
            <div class="col-md-4 lead">
              Delivery Preference:
            </div>
            <div class="col-md-4">
              {{ po.shipping_code }}
            </div>
          </div>

          <br>

          <div v-if="po.custom_po">
            <div class="container mb-2">
            <div class="col-md-4 lead">
              PO #:
            </div>
            <div class="col-md-4">
              {{ po.custom_po }}
              </div>
            </div>

            <br>
          </div>

          <div v-if="po.custom_note">
            <div class="container mb-2">
              <div class="col-md-4 lead">
                Special Instructions:
              </div>
              <div class="col-md-4">
                {{po.custom_note}}
              </div>
            </div>
          </div>
        </div>
        <!-- shiping info end -->
        <hr class="mt-5" />
        <br>

        <!-- web po desc -->
        <div>
          <p>
            <u>Terms &amp; Conditions</u><br />
            The Terms and Conditions of Sale at
            <a href="/company/terms-conditions" class="blue_link"
              >www.malabs.com/terms-conditions</a
            >
            apply to all transactions. <br /><br />
          </p>
          <p>
            <u>Price and Availability</u><br />
            Please check with your account manager for final pricing and product
            availability (subject to change without prior notice). Unless
            otherwise indicated, all price quotes are based on a cash discount
            offered to all buyers for payment by cash or check as permitted by
            law. Some Brands may not be eligible for sale outside the U.S. Please
            review with your account manager. All logistics charges, fees, costs,
            and taxes are subject to change based on final destination.
            <br /><br />
          </p>

          <p>
            <u>Destination Control Statement</u><br />
            Diversion contrary to US export laws of goods or services purchased
            from Ma Labs is strictly prohibited.
            <br /><br />
          </p>
        </div>
        
        <!-- web po desc end-->

      </div>
    </div>


    

  </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import submenuAccount from '../../components/nav/submenuAccount.vue'

import { ref } from "vue";
// import { useRouter } from 'vue-router'
import { getAuthAPI } from "../../utils/axios-api";
import { formatPrice } from '../../utils/common';

export default {
  name: "WebpoDetails",
  components: { Spinner, BreadcrumbPage,submenuAccount },
  props: ["webso"],
  setup(props, context) {
    const loading = ref(true);
    const error = ref("");
    const webso = ref(props.webso);
    const po = ref({})
    const po_items = ref([])

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Order Status", link: "WebOrder"})
    breadcrumb_links.value.push({text: "Web P.O.", link: "WebOrder"})
    breadcrumb_links.value.push({text: webso.value, link: ""})

    var url = "/order/web-po/details/" + webso.value;

    const printWindow = () => {
        window.print();  
    }

    getAuthAPI
      .get(url)
      .then((response) => {
        po.value = response.data.po
        po_items.value = response.data.po_items
        

        loading.value = false;
      })
      .catch((err) => {
        loading.value = false;
        try {
          error.value = err.message;
        }
        catch {
          error.value = "No record found."
        }
      });

    return {
      loading,
      webso,
      po,
      po_items,
      breadcrumb_links,
      formatPrice,
      
    };
  },
};
</script>

<style scoped>
.lead{
    color:black;
    font-size: 1.2em;
}
.icon {
padding-left:10px;
font-size:2.5rem;
color:#888;
}
.desc {
  max-width: 450px;
}

@media print {
    #footer {
      display: none !important;
    }
}

</style>
